import React from 'react'
import { graphql } from 'gatsby'
import Img from "gatsby-image"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import ScheduleConsultation from '../components/page-sections/schedule-consultation'


const RhythmicDosingPage = (props) => (
  <Layout location={props.location}>
    <SEO title="G Jones for Hormones" />
    <Container>
      <Row style={{ marginBottom: '3rem', marginTop: '3rem' }}>
        <Col>
          <h1>Rhythmic Dosing</h1>
          <section className="clearfix">
            <Img
              className="img-fluid rounded float-md-right ml-md-4 mb-4"
              fluid={props.data.image1.childImageSharp.fluid}
              alt=""
              style={{
                width: '100%',
                maxWidth: '425px'
              }}
            />
            <p>
              Physiologic Restoration is a method of rhythmic hormone dosing that mimics a normal young woman's menstrual cycle. Estrogen and progesterone is not all the same and can come from different suppliers. You want to be sure that you are using a reputable pharmacy that does not cut corners and will not dissolve low quality hormones in substances that are quicker but can have adverse effects in your body. The reason some may do this is because it is faster and cheaper. If you choose to use a CRT pharmacy that means they are registered pharmacies who are required to buy all the ingredients from designated sources and dissolve the powders in glycerine (an inert substance) overnight. The base cream that these pharmacies use has no pesticides or chemicals in it and is mostly made of coconut oil and borage.
            </p>
            <p>
              Your creams are dosed in increments of 1 ml at the percentage prescribed by your doctor. You will receive a calendar with the dosing of each hormone to mimic an ovulatory cycle that your body did naturally when you had full functioning ovaries. Essentially we mimic the three phases of a female menstrual cycle beginning with the follicular phase, ovulatory phase and ending with the luteal phase and a 3-5 day period if you have an intact uterus. If it is working correctly in the uterus, it is working in the brain, bones, and the rest of your body to repair and restore. Try it for yourself, the results are self evident!
            </p>
          </section>
        </Col>
      </Row>
    </Container>
    <ScheduleConsultation />
  </Layout>
)

export default RhythmicDosingPage

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "rhythmic-dosing/pink_flowers.jpg" }) {
      ...image43
    }
  }
`
